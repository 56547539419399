import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Link as RouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { logoutUser, selectAuth } from "../features/auth/authSlice";
import {
  fetchMyself,
  resetUserAccount,
  selectUsers,
} from "../features/users/usersSlice";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BrushIcon from "@material-ui/icons/Brush";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EmailIcon from "@material-ui/icons/Email";
import ListIcon from "@material-ui/icons/List";
import LocalOfferRoundedIcon from "@material-ui/icons/LocalOfferRounded";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MenuIcon from "@material-ui/icons/Menu";
import ReceiptIcon from "@material-ui/icons/Receipt";
import RedeemIcon from "@material-ui/icons/Redeem";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import StoreIcon from "@material-ui/icons/Store";
import GroupIcon from "@material-ui/icons/Group";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import { setXClientHost } from "../app/utils";
import { resetCart } from "../features/carts/cartsSlice";
import { fetchLogo, selectContents } from "../features/content/contentsSlice";
import { selectCustomisation } from "../features/customisation/customisationSlice";
import { resetOrder } from "../features/orders/ordersSlice";
import { resetProduct } from "../features/products/productsSlice";
import CSVFeedback from "../features/snackbar/CSVFeedback";
import { resetSnack } from "../features/snackbar/snackbarSlice";
import { resetWishlistCart } from "../features/wishlist/wishlistSlice";

function ListItemLink(props) {
  const classes = useStyles();
  const { onClick, selected, icon, primary, show, to, sub } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    show && (
      <li>
        <ListItem
          selected={selected}
          onClick={onClick}
          button
          component={renderLink}
          classes={{
            selected: classes.selected, //
          }}
        >
          {icon ? (
            <ListItemIcon>{icon}</ListItemIcon>
          ) : (
            <ListItemIcon></ListItemIcon>
          )}
          <ListItemText
            style={{ color: "#404040" }}
            // style={sub ? {fontSize: '0.9rem'} : {fontSize: '1rem'}}
            primary={primary}
            className={sub ? classes.sub : classes.parent}
          />
        </ListItem>
      </li>
    )
  );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  sub: {
    color: "rgba(109, 113, 117, 1)",
    fontSize: "0.9rem",
  },
  selected: {
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
    borderLeftSize: 5,
    paddingLeft: "11px",
  },

  container: {
    alignItems: "center",
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.headerColor.main,
    // borderBottom: `2px solid ${theme.palette.secondary.main}`,
    display: "flex",
    flexDirection: "row",
    height: "115px",
    justifyContent: "center",
  },
  headerItemsContainer: {
    display: "flex",
    marginLeft: "auto",
  },
  headerItem: {
    backgroundColor: theme.palette.headerButton.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.headerButtonFont.main,
    borderRadius: "24px",
    color: theme.palette.headerButtonFont.main,
    // fontSize: 24,
    // fontSize: 20,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    textTransform: "capitalize",
    // width: 120,
    cursor: "pointer",
    marginRight: 32,
    fontSize: "0.5em",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    minHeight: "74px !important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function Header() {
  const { customisation } = useSelector(selectCustomisation);
  const midpoint = useMediaQuery("(max-width:1450px)");

  const history = useHistory();
  const theme = useTheme();
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const { logout, signedIn } = useSelector(selectAuth);
  const { userAccount } = useSelector(selectUsers);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const handleChildClick = (index, event) => {
    handleDrawerClose();
    setSelectedIndex(index);
  };

  const list = [
    {
      name: "Members",
      url: "/admin/members",
      icon: <AccountCircleIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Orders",
      url: "/admin/orders",
      icon: <ShoppingCartRoundedIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Emails",
      url: "/admin/emails",
      icon: <EmailIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Points Summary",
      url: "/admin/emails/summary",
      icon: <SubdirectoryArrowRightIcon />,
      show: open
      ? location.pathname.includes("/emails") &&
        userAccount.roleID == 1
      : false,
      sub: true,
    },
    ...(process.env.REACT_APP_ENABLE_CLAIMS
      ? [
          {
            name: "Claims",
            url: "/admin/claims",
            icon: <ReceiptIcon />,
            show: true,
            sub: false,
          },
        ]
      : []),
    {
      name: "Reports",
      url: "/admin/reports/redeemed",
      icon: <AssessmentIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Points Redeemed",
      url: "/admin/reports/redeemed",
      icon: <SubdirectoryArrowRightIcon />,
      show: open ? location.pathname.includes("/reports") : false,

      sub: true,
    },
    {
      name: "Points Expired",
      url: "/admin/reports/expired",
      icon: <SubdirectoryArrowRightIcon />,
      show: open ? location.pathname.includes("/reports") : false,

      sub: true,
    },
    {
      name: "Program Snapshot",
      url: "/admin/reports/snapshot",
      icon: <SubdirectoryArrowRightIcon />,
      show: open ? location.pathname.includes("/reports") : false,

      sub: true,
    },
    {
      name: "Reporting Dashboard",
      url: "/admin/reports/dashboard",
      icon: <SubdirectoryArrowRightIcon />,
      show: open ? location.pathname.includes("/reports") : false,

      sub: true,
    },
    {
      name: "Bulk Upload History",
      url: "/admin/uploads",
      icon: <ListIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Products",
      url: "/admin/products",
      icon: <LocalOfferRoundedIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Distributors",
      url: "/admin/distributors",
      icon: <LocalShippingIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Stores",
      url: "/admin/stores",
      icon: <StoreIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Member Groups",
      url: "/admin/membergroups",
      icon: <GroupIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Customisation",
      url: "/admin/customisation",
      icon: <BrushIcon />,
      show: userAccount.roleID <= 2,
      sub: false,
    },
    {
      name: "Theme",
      url: "/admin/customisation",
      icon: <SubdirectoryArrowRightIcon />,
      show: open
        ? location.pathname.includes("/customisation") &&
          userAccount.roleID <= 2
        : false,
      sub: true,
    },
    {
      name: "Images",
      url: "/admin/customisation/images",
      icon: <SubdirectoryArrowRightIcon />,
      show: open
        ? location.pathname.includes("/customisation") &&
          userAccount.roleID <= 2
        : false,
      sub: true,
    },
    {
      name: "Page Content",
      url: "/admin/customisation/content",
      icon: <SubdirectoryArrowRightIcon />,
      show: open
        ? location.pathname.includes("/customisation") &&
          userAccount.roleID <= 2
        : false,
      sub: true,
    },
    {
      name: "Site Variables",
      url: "/admin/customisation/variables",
      icon: <SubdirectoryArrowRightIcon />,
      show: open
        ? location.pathname.includes("/customisation") &&
          userAccount.roleID <= 2
        : false,
      sub: true,
    },
    {
      name: "Email Templates",
      url: "/admin/customisation/email",
      icon: <SubdirectoryArrowRightIcon />,
      show: open
        ? location.pathname.includes("/customisation") &&
          userAccount.roleID <= 2
        : false,
      sub: true,
    },
    {
      name: "Programs",
      url: "/admin/programs",
      icon: <RedeemIcon />,
      show: true,
      sub: false,
    },
  ];

  const salespersonList = [
    {
      name: "Members",
      url: "/admin/members",
      icon: <AccountCircleIcon />,
      show: true,
      sub: false,
    },
    {
      name: "Reporting Dashboard",
      url: "/admin/reports/dashboard",
      icon: <AssessmentIcon />,
      show:
        process.env.REACT_APP_CLIENT_ID == 13 ||
        process.env.REACT_APP_CLIENT_ID == 18
          ? false
          : true,
      sub: false,
    },
  ];
  const managerList = [
    {
      name: "Members",
      url: "/admin/members",
      icon: <AccountCircleIcon />,
      show: true,
      sub: false,
    },
  ];
  let jwtToken = localStorage.getItem("clientToken");

  useEffect(() => {
    if (
      window.location.pathname.includes("signin") ||
      window.location.pathname.includes("resetpassword") ||
      window.location.pathname.includes("help")
    ) {
      setShow(false);
    } else {
      setShow(true);
      if (!jwtToken) {
        handleLogout();
      }
    }
  }, [window.location.pathname]);

  // useEffect(() => {
  //   if (logout) {
  //     handleLogout();
  //   }
  // }, [logout]);

  const handleLogout = () => {
    localStorage.removeItem("clientUser");
    localStorage.removeItem("clientToken");
    setShow(false);
    dispatch(logoutUser({ token: jwtToken }));
    dispatch(resetWishlistCart());
    dispatch(resetCart());
    dispatch(resetSnack());
    dispatch(resetProduct());
    dispatch(resetOrder());
    dispatch(resetUserAccount());

    history.push("/signin");
  };

  useEffect(() => {
    if (!signedIn) {
      dispatch(
        fetchMyself({
          token: jwtToken,
        })
      );
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogoClick = () => {
    // let logoContent = contentsList.filter((content) => content.name === "logo");
  };

  const { logo } = useSelector(selectContents);
  useEffect(() => {
    if (!logo.value) {
      dispatch(
        fetchLogo({
          host: setXClientHost(),
          token: jwtToken,
          name: "logo",
        })
      );
    }
  }, [logo]);

  // style={{props.location.pathname.includes('signin')}}
  if (window.location.pathname.includes('signin') && (process.env.REACT_APP_CLIENT_ID == "17" || process.env.REACT_APP_CLIENT_ID == "19")) {
    return <div></div>;
  } else {
    return (
      <div className={classes.container}>
        {signedIn && userAccount.roleID <= 4 ? (
          <div
            style={{
              color: "#fff",
              marginLeft: 24,
              marginRight: 24,
            }}
          >
            <IconButton
              // color="inherit"
              style={{ color: theme.palette.highlight.main }}
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              onMouseOver={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onMouseLeave={handleDrawerClose}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction !== "rtl" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                {userAccount.roleID > 3
                  ? salespersonList.map((listItem, index) => (
                      <ListItemLink
                        key={index}
                        to={listItem.url}
                        primary={listItem.name}
                        icon={listItem.icon}
                        show={listItem.show}
                        onClick={(e) => handleChildClick(index, e)}
                        selected={selectedIndex === index}
                        sub={listItem.sub}
                      />
                    ))
                  : userAccount.roleID > 2
                  ? managerList.map((listItem, index) => (
                      <ListItemLink
                        key={index}
                        to={listItem.url}
                        primary={listItem.name}
                        icon={listItem.icon}
                        show={listItem.show}
                        onClick={(e) => handleChildClick(index, e)}
                        selected={selectedIndex === index}
                        sub={listItem.sub}
                      />
                    ))
                  : list.map((listItem, index) => (
                      <ListItemLink
                        key={index}
                        to={listItem.url}
                        primary={listItem.name}
                        icon={listItem.icon}
                        show={listItem.show}
                        onClick={(e) => handleChildClick(index, e)}
                        selected={selectedIndex === index}
                        sub={listItem.sub}
                      />
                    ))}
              </List>
            </Drawer>
          </div>
        ) : (
          <div></div>
        )}
        <Link to="/">
          <div style={{ marginLeft: 24, marginRight: 24 }}>
            {/* <img src={`/${customisation.theme.logo}`} alt="logo" height="50px" /> */}
            {logo.value && (
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/theme/logo/${logo.value}`}
                alt="logo"
                height="90px"
              />
            )}
          </div>
        </Link>
        {signedIn && (
          <div className={classes.headerItemsContainer}>
            <CSVFeedback />

            {process.env.REACT_APP_ENABLE_LEADERBOARD === "true" &&
              ((process.env.REACT_APP_CLIENT_ID !== "17" && process.env.REACT_APP_CLIENT_ID !== "19") ||
                userAccount.roleID <= 4) && (
                <Link to="/leaderboard">
                  <div className={classes.headerItem}>Leaderboard</div>
                </Link>
              )}
            {process.env.REACT_APP_ENABLE_CLAIMS == "true" && (
              <Link to="/invoice">
                <div className={classes.headerItem}>Claim Form</div>
              </Link>
            )}
            <Link to="/account">
              <div className={classes.headerItem}>My Account</div>
            </Link>
            <div
              style={{ marginRight: 16 }}
              className={classes.headerItem}
              onClick={handleLogout}
            >
              Sign Out
            </div>
          </div>
        )}
      </div>
    );
  }
}
