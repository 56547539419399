import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateClient, fetchClientByID, selectClients } from "../clients/clientsSlice"; // Adjust the import path as needed

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles, withStyles } from "@material-ui/core/styles";

// Utility function to add ordinal suffix
const addOrdinalSuffix = (n) => {
    if (n === -1) return 'Last Date of the Month';

    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const useStyles = makeStyles((theme) => ({
    customisationContainer: {
        padding: 24,
        borderRadius: 16,
        color: theme.palette.primaryFont.main,
        fontSize: 16,
        width: "75%",
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 16,
        color: theme.palette.secondaryFont.main,
    },
    configSection: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginTop: 16,
    },
    frequencyContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    frequencyRow: {
        display: "flex",
        alignItems: "center",
        gap: 16,
    },
    saveButton: {
        marginTop: 16,
        alignSelf: "flex-start",
        position: "relative",
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 0, 0),
        backgroundColor: theme.palette.primaryButton.main,
        color: theme.palette.primaryButtonFont.main,
        "&:hover": {
            backgroundColor: theme.palette.primaryButton.main,
            opacity: 0.7,
        },
        width: 200,
    },
}))(Button);

export default function PointsSummary() {
    const classes = useStyles();
    const dispatch = useDispatch();
    let jwtToken = localStorage.getItem("clientToken");

    // Select client data and loading state from Redux state
    const { client, loading, error } = useSelector(selectClients);

    // State for points summary email configuration
    const [isPointsSummaryEnabled, setIsPointsSummaryEnabled] = useState(
        client.enablePointsSummaryEmails || false
    );
    const [repeatEvery, setRepeatEvery] = useState(
        client?.pointsSummaryEmailFreqValue || 1
    );
    const [repeatPeriod, setRepeatPeriod] = useState(
        client?.pointsSummaryEmailFreqUnit || 'month'
    );
    const [specificValue, setSpecificValue] = useState(
        client?.pointsSummaryEmailDayValue || 1
    );

    // console.log('isPointsSummaryEnabled', isPointsSummaryEnabled)
    // console.log('isPointsSummaryEnabledClient', client.enablePointsSummaryEmails)

    // Fetch client data on component mount
    useEffect(() => {
        dispatch(
            fetchClientByID({
                token: jwtToken,
                clientId: process.env.REACT_APP_CLIENT_ID
            })
        );
    }, []);

    // Update local state when client data changes
    useEffect(() => {
        if (client) {
            setIsPointsSummaryEnabled(client.enablePointsSummaryEmails || false);
            setRepeatEvery(client.pointsSummaryEmailFreqValue || 1);
            setRepeatPeriod(client.pointsSummaryEmailFreqUnit || 'month');
            setSpecificValue(client.pointsSummaryEmailDayValue || 1);
        }
    }, [client]);

    // Handle switch change
    const handleSwitchChange = (event) => {
        setIsPointsSummaryEnabled(event.target.checked);
    };

    // Handle repeat every change
    const handleRepeatEveryChange = (event) => {
        const value = Math.max(1, parseInt(event.target.value) || 1);
        setRepeatEvery(value);
    };

    // Handle repeat period change
    const handleRepeatPeriodChange = (event) => {
        setRepeatPeriod(event.target.value);
        // Reset specific value when changing period
        setSpecificValue(event.target.value === 'week' ? 1 : 1);
    };

    // Handle specific value change
    const handleSpecificValueChange = (event) => {
        setSpecificValue(event.target.value);
    };

    // Save configuration handler
    const handleSaveConfiguration = async () => {
        // Prepare points summary configuration
        try {
            // Dispatch update client action
            await dispatch(updateClient({
                token: jwtToken,
                clientData: {                    
                    enablePointsSummaryEmails: isPointsSummaryEnabled,
                    pointsSummaryEmailFreqValue: repeatEvery,
                    pointsSummaryEmailFreqUnit: repeatPeriod,
                    pointsSummaryEmailDayValue: specificValue,
                    pointsSummaryEmailDayUnit: repeatPeriod == 'week' ? 'day_of_week' : 'date_of_month',
                },
                clientId: process.env.REACT_APP_CLIENT_ID
            })).unwrap();
        } catch (error) {
            // Error handling is done in the thunk
            console.error("Failed to save configuration", error);
        }
    };

    // Determine label and options for specific value input
    const getSpecificValueConfig = () => {
        switch (repeatPeriod) {
            case 'day':
                return { label: null, options: [] };
            case 'week':
                return {
                    label: 'Send on',
                    options: [
                        { value: 1, label: 'Sunday' },
                        { value: 2, label: 'Monday' },
                        { value: 3, label: 'Tuesday' },
                        { value: 4, label: 'Wednesday' },
                        { value: 5, label: 'Thursday' },
                        { value: 6, label: 'Friday' },
                        { value: 7, label: 'Saturday' }
                    ]
                };
            case 'month':
                return {
                    label: 'Send on',
                    options: [
                        ...Array.from({ length: 28 }, (_, i) => ({
                            value: i + 1,
                            label: addOrdinalSuffix(i + 1)
                        })),
                        { value: -1, label: 'Last Date of the Month' }
                    ]
                };
            default:
                return { label: null, options: [] };
        }
    };

    const specificValueConfig = getSpecificValueConfig();

    // Loading state
    if (!client) {
        return (
            <Paper elevation={3} className={classes.customisationContainer}>
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            </Paper>
        );
    }

    return (
        <Paper elevation={3} className={classes.customisationContainer}>
            <div className={classes.title}>Points Summary Email</div>

            <div className={classes.configSection}>
                {/* Enable/Disable Switch */}
                <FormControlLabel
                    control={
                        <Switch
                            checked={isPointsSummaryEnabled}
                            onChange={handleSwitchChange}
                            color="primary"
                        />
                    }
                    label="Enable Points Summary Emails"
                />

                {/* Frequency Configuration */}
                {isPointsSummaryEnabled && (
                    <div className={classes.frequencyContainer}>
                        <div className={classes.frequencyRow}>
                            <TextField
                                label="Send every"
                                type="number"
                                value={repeatEvery}
                                onChange={handleRepeatEveryChange}
                                inputProps={{ min: 1 }}
                                variant="outlined"
                            />

                            <FormControl variant="outlined">
                                <InputLabel>Time Period</InputLabel>
                                <Select
                                    value={repeatPeriod}
                                    onChange={handleRepeatPeriodChange}
                                    label="Time Period"
                                >
                                    <MenuItem value="day">Day</MenuItem>
                                    <MenuItem value="week">Week</MenuItem>
                                    <MenuItem value="month">Month</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {/* Specific Value Input (Week/Month) */}
                        {specificValueConfig.label && (
                            <div className={classes.frequencyRow}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>{specificValueConfig.label}</InputLabel>
                                    <Select
                                        value={specificValue}
                                        onChange={handleSpecificValueChange}
                                        label={specificValueConfig.label}
                                    >
                                        {specificValueConfig.options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                    </div>
                )}
                <div>
                    <ColorButton
                        variant="contained"
                        className={classes.saveButton}
                        onClick={handleSaveConfiguration}

                    >
                        Save Configuration
                    </ColorButton>
                </div>
            </div>
        </Paper>
    );
}