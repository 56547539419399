import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MemberTable from "../../common/MemberTable";
import {
  fetchUserPointsHistory,
  fetchUserPointsByPointsID,
  fetchMoreUserPoints,
  selectUserPoints,
  fetchMoreUserPointsHistory
} from "../userPoints/userPointsSlice";
import { selectUsers } from "./usersSlice";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));


export default function MemberClaims(props) {
  const { userAccount } = props;
  let jwtToken = localStorage.getItem("clientToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [orderNames, setOrderNames] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  const { claims, error, loading, usersList, usersFilter, user } =
    useSelector(selectUsers);
  const [statusNames, setStatusNames] = useState([]);
  const [statusIDs, setStatusIDs] = useState([]);
  const [statusList, setStatusList] = useState([
    { id: 1, value: "Active", name: "Active" },
    { id: 2, value: "Expired", name: "Expired" },
    { id: 3, value: "Redeemed", name: "Redeemed" },
    { id: 4, value: "Complimentary", name: "Complimentary" },
  ]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const { pointsList, pointsAmount, pointsHistory } = useSelector(selectUserPoints);

  const headCells = [
    {
      id: "updatedAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Date",
      long: false,
    },

    {
      id: "notes",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Notes",
      long: false,
    },

    {
      id: "points",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Points",
      long: false,
    },
    {
      id: "expiryDate",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Expiry Date",
      long: false,
    },
    {
      id: "statusName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Status",
      long: false,
    },
  ];

  useEffect(() => {
    // dispatch(fetchUserPoints({ token: jwtToken, userID: user.id }));
    dispatch(
      fetchUserPointsHistory({
        token: jwtToken,
        userID: user.id,
      })
    );
  }, []);

  const handleClaimsChange = (event) => {
    let idsArr = [];
    // event.target.value.forEach((selected) => {
    let selectedOrder = points.filter(
      (order) => order.orderNumber === event.target.value
    );
    selectedOrder && idsArr.push(selectedOrder[0].id);
    // });

    setOrderNames(event.target.value);
    setUserIDs(idsArr);
  };

  const handleStatusChange = (event) => {
    let idsArr = [];
    event.target.value.forEach((selected) => {
      let selectedStatus = statusList.filter(
        (status) => status.name === selected
      );
      selectedStatus[0] && idsArr.push(selectedStatus[0].id);
    });

    setStatusNames(event.target.value);
    setStatusIDs(idsArr);
  };

  // useEffect(() => {
  //   if (loading === 'idle' || !usersList.length) {
  //     dispatch(
  //       fetchUsers({
  //         query: '?statuses=1&limit=200',
  //         token: jwtToken,
  //       })
  //     );
  //   }
  // }, [loading, dispatch]);

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {
    // dispatch(
    //   fetchUserByID({
    //     id: id,
    //     token: jwtToken,
    //   })
    // );
  };

  const handleEditClick = (id) => {
    // const selectedPoint = pointsFilter.filter((point) => point.id === id);
    // dispatch(setPoint(selectedPoint[0]));

    dispatch(
      fetchUserPointsByPointsID({
        userID: user.id,
        pointsID: id,
        token: jwtToken,
      })
    );

  };

  const handleSearch = () => {
    // const searchQuery = `?&limit=200&statuses=${statusIDs.join(
    //   ','
    // )}&name=${orderNames}`;
    // dispatch(
    //   filterUsers({
    //     query: searchQuery,
    //     token: jwtToken,
    //   })
    // );
  };

  const handleClear = () => {
    setOrderNames([]);
    setUserIDs([]);
    setStatusNames([]);
    setStatusIDs([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    // Calculate the offset based on the page number
    const offset = newPage * rowsPerPage;

    // Only fetch more data if we're on a new page and need more data
    if (offset >= pointsHistory.length) {
      dispatch(
        fetchMoreUserPointsHistory({
          token: jwtToken,
          userID: user.id,
          // query: `limit=25`,
          query: `limit=${rowsPerPage}`,
          offset: offset
        })
      );
    }
  };

  const selectNames = ["order", "status"];

  const cellList = headCells.map((cell) => {
    return cell.id;
  });

  const points = [
    {
      id: 3,
      date: "16-08-2019",
      time: "9:57",
      description: "User update",
      points: 500,
    },
    {
      id: 4,
      date: "07-08-2020",
      time: "11:40",
      description: "Employee Point load request from Head Office",
      points: 750,
    },
    {
      id: 4,
      date: "13-02-2021",
      time: "6:00",
      description: "User update",
      points: 250,
    },
  ];

  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded" || loading === "idle") {
    content = (
      <MemberTable
        data={pointsHistory}
        restricted={userAccount.roleID > 2}
        actionsLists={[props.pointsList, statusList]}
        actionsHandleChange={[handleClaimsChange, handleStatusChange]}
        actionsNames={[orderNames, statusNames]}
        headCells={headCells}
        cellList={cellList}
        handleEditClick={handleEditClick}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleEdit={handleEdit}
        area={"points"}
        selectNames={selectNames}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        totalCount={pointsAmount}
      />
    );
  }

  return (
    <Grid
      key={props.pointsList ? props.pointsList.length : 1}
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        height: "100%",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {content}
    </Grid>
  );
}
