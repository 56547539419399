import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import StatesDropDown from "../../common/StatesDropDown";
import { selectUsers, updateUser } from "../users/usersSlice";
import { uuidv4 } from "../../app/utils";
import { fetchClientCountry, selectClients } from "../clients/clientsSlice.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const BackButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "transparent",
    color: "#fff",
    cursor: "pointer",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#fff",
    borderRadius: 20,
    // width: 100,
    maxHeight: 38.5,
    "&:hover": {
      backgroundColor: "#0000004f",
    },
    fontWeight: "bold",
  },
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    border: `1px solid ${theme.palette.secondaryButtonFont.main}`,
    borderRadius: "24px",
    padding: 4,
    paddingLeft: 12,
    paddingRight: 12,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    display: "flex",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
  },
  accountContainer: {
    height: "100%",
    color: theme.palette.primaryFont.main,
  },
  topBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  registerContainer: {
    borderRadius: 16,
    backgroundColor: "#fff",
    color: theme.palette.secondaryFont.main,
    padding: "50px !important",
  },
  textLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
    marginTop: 24,
  },
  textInput: {},
  userDetailsContainer: {
    padding: 24,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  userDetails: {
    color: "#fff",
    fontSize: 40,
  },
  infoContainerEdit: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
  },
  avatarCardContainerEdit: {
    display: "flex",
    marginBottom: "2rem",
  },
  avatarCardEdit: {
    borderRadius: 16,
    padding: 24,
    textAlign: "center",
  },
  pointsBalanceCardContainerEdit: {
    display: "flex",
    flexGrow: 1,
  },
  pointsBalanceCardEdit: {
    borderRadius: 16,
    padding: 24,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  userCardsContainerEdit: {
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  historyCardContainerEdit: {
    display: "flex",
    flexGrow: 1,
  },
  historyCardEdit: {
    flexGrow: 1,
    padding: 24,
    borderRadius: 16,
    width: "100%",
  },
  cardHeader: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 40,
  },
  header: {
    color: theme.palette.primaryFont.main,
  },
  pointsColor: {
    color: theme.palette.highlight.main,
  },
  emailNotifContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Register() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userAccount } = useSelector(selectUsers);
  const client = useSelector(selectClients);
  const [username, setUsername] = useState("");
  const matches = useMediaQuery("(min-width:920px)");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [streetName, setStreetName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [streetNameError, setStreetNameError] = useState("");

  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [emailNotifications, setEmailNotifications] = useState(null);

  useEffect(() => {
    dispatch(fetchClientCountry({}));
  }, []);

  useEffect(() => {
    setUsername(userAccount.username);
    setFirstName(userAccount.firstName);
    setLastName(userAccount.lastName);
    setEmail(userAccount.email);
    setMobile(userAccount.mobile);
    setEmailNotifications(userAccount.emailNotif);

    if (userAccount.postalAddress) {
      setStreetName(userAccount.postalAddress.streetName);
      setCompanyName(userAccount.postalAddress.companyName);
      setSuburb(userAccount.postalAddress.suburb);
      setPostCode(userAccount.postalAddress.postCode);
      setCity(userAccount.postalAddress.city);
      setState(userAccount.postalAddress.state.toUpperCase());
      setCountry(userAccount.postalAddress.country);
    }
  }, [userAccount]);

  const isPOBox = (str) => {
    const regTest =
      /\b(p\.?\s*o\.?|post\s+office|post)(\s+)?(?:box|[0-9]*)?\b/i;
    return regTest.test(str);
  };

  let jwtToken = localStorage.getItem("clientToken");
  const handleEditSubmit = (event) => {
    event.preventDefault();
    if (isPOBox(streetName)) {
      setStreetNameError("We are unable to accept a PO Box address");
    } else {
      setStreetNameError("");
      const userDetails = {
        statusID: 1,
        clientID: Number(process.env.REACT_APP_CLIENT_ID),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        displayName: (firstName + " " + lastName).trim(),
        email: email.trim(),
        mobile:
          mobile == ""
            ? uuidv4()
            : mobile.startsWith("+")
            ? mobile
            : client.country.countryName == "Australia"
            ? "+61" + mobile.replace(/^0+/, "")
            : "+64" + mobile.replace(/^0+/, ""),
        company: companyName.trim(),
        emailNotif: emailNotifications,
        postalAddress: {
          companyName: companyName.trim(),
          streetName: streetName.trim(),
          suburb: suburb.trim(),
          postCode: postCode.trim(),
          city: city.trim(),
          state: state.trim(),
          country: country.trim(),
        },
      };
      dispatch(
        updateUser({ token: jwtToken, id: userAccount.id, user: userDetails })
      );
    }
  };

  return (
    <div id="account-background" className={classes.topBackground}>
      <div
        className={classes.userDetailsContainer}
        style={{ flexDirection: matches ? "row" : "column" }}
      >
        <div className={classes.userDetails}>
          <div style={{ fontWeight: "bold", marginBottom: 24 }}>
            {userAccount.displayName ? userAccount.displayName : "HMSNZ"}'s
            Account
          </div>
          <div style={{ fontSize: 24, fontWeight: "bold" }}>
            Member Since:{" "}
            {userAccount.createdAt &&
              userAccount.createdAt
                .substring(0, 10)
                .split("-")
                .reverse()
                .join("/")}
          </div>
        </div>
        <div className={classes.editButtonContainer}>
          <div onClick={handleEditSubmit}>
            <BackButton>Save Changes</BackButton>
          </div>
        </div>
      </div>
      <div className={classes.paper}>
        <div
          className={classes.userCardsContainerEdit}
          style={{ flexFlow: matches ? "row wrap" : "column" }}
        >
          <div
            className={classes.historyCardContainerEdit}
            style={{
              flexDirection: matches ? "row" : "column",
              alignItems: matches ? "flex-start" : "center",
              marginRight: matches ? 80 : 0,
            }}
          >
            <Paper elevation={6} className={classes.historyCardEdit}>
              <form className={classes.form} onSubmit={handleEditSubmit}>
                <div
                  style={{
                    fontSize: 48,
                    fontWeight: "bold",
                    marginTop: 32,
                  }}
                  className={classes.header}
                >
                  Account Information
                </div>
                <div className={classes.textLabel}>Email: </div>
                <TextField
                  value={email}
                  className={classes.textInput}
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className={classes.textLabel}>First Name: </div>
                <TextField
                  value={firstName}
                  className={classes.textInput}
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="firstName"
                  name="firstName"
                  autoComplete="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <div className={classes.textLabel}>Surname: </div>
                <TextField
                  value={lastName}
                  className={classes.textInput}
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="lastName"
                  name="lastName"
                  autoComplete="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                />

                <div className={classes.textLabel}>Username: </div>
                <TextField
                  value={username}
                  className={classes.textInput}
                  variant="outlined"
                  margin="dense"
                  required
                  disabled
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                />

                {/* <div className={classes.textLabel}>Password: </div>
              <TextField
              value={}
                className={classes.textInput}
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="password"
                name="password"
                autoComplete="password"
                onChange={(e) => setPassword(e.target.value)}
              /> */}

                <div className={classes.textLabel}>
                  Mobile:
                  <br />
                  {/* <span style={{ fontSize: 12 }}>
                Number must start with the country code +61 or +64                  
              </span> */}
                </div>
                <div className={classes.textInput}>
                  <TextField
                    value={mobile}
                    // key={mobile}
                    className={classes.textInput}
                    variant="outlined"
                    margin="dense"
                    required={process.env.REACT_APP_MOBILE_UNREQUIRED != "true"}
                    fullWidth
                    id="mobile"
                    name="mobile"
                    autoComplete="mobile"
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    fontSize: 48,
                    fontWeight: "bold",
                    marginTop: 32,
                  }}
                  className={classes.header}
                >
                  Delivery Address
                </div>
                <Grid container direction="column">
                  <Grid item sm>
                    <div className={classes.textLabel}>
                      Company Name{" "}
                      <span style={{ fontSize: 12 }}>(if applicable)</span>:{" "}
                    </div>
                    <TextField
                      value={companyName}
                      className={classes.textItem}
                      variant="outlined"
                      margin="dense"
                      id="company-number-street"
                      type="text"
                      fullWidth
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm>
                    <div className={classes.textLabel}>Street Address: </div>
                    <TextField
                      value={streetName}
                      className={classes.textItem}
                      variant="outlined"
                      margin="dense"
                      id="street-number-street"
                      type="text"
                      fullWidth
                      onChange={(e) => setStreetName(e.target.value)}
                      error={streetNameError}
                      helperText={streetNameError ? streetNameError : ""}
                    />
                  </Grid>
                  {/* <Grid item sm>
                <Grid container direction="row" spacing={2}> */}
                  <Grid item sm>
                    <div className={classes.textLabel}>Suburb: </div>
                    <TextField
                      value={suburb}
                      className={classes.textItem}
                      variant="outlined"
                      margin="dense"
                      id="subarb-street"
                      type="text"
                      fullWidth
                      onChange={(e) => setSuburb(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm>
                    <div className={classes.textLabel}>Post Code: </div>
                    <TextField
                      value={postCode}
                      className={classes.textItem}
                      variant="outlined"
                      margin="dense"
                      id="post-code-street"
                      type="text"
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                      onChange={(e) => setPostCode(e.target.value)}
                    />
                  </Grid>
                  {process.env.REACT_APP_CLIENT_ID != "11" &&
                    process.env.REACT_APP_CLIENT_ID != "12" && (
                      <Grid item sm>
                        <div className={classes.textLabel}>
                          City:{" "}
                          <span
                            className={classes.textLabel}
                            style={{ fontSize: 12 }}
                          >
                            (if applicable)
                          </span>{" "}
                        </div>
                        <TextField
                          value={city}
                          className={classes.textItem}
                          variant="outlined"
                          margin="dense"
                          id="city-street"
                          type="text"
                          fullWidth
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Grid>
                    )}
                  <Grid item sm>
                    <div className={classes.textLabel}>State/Territory: </div>
                    {/* <TextField
                      value={state}
                      className={classes.textItem}
                      variant="outlined"
                      margin="dense"
                      id="state-street"
                      type="text"
                      fullWidth
                      onChange={(e) => setState(e.target.value)}
                    /> */}
                    <StatesDropDown
                      placeholder={"State/Territory"}
                      setSelectedState={setState}
                      state={state}
                    />
                  </Grid>
                  <Grid item sm>
                    <div className={classes.textLabel}>Country: </div>
                    <TextField
                      value={country}
                      className={classes.textItem}
                      variant="outlined"
                      margin="dense"
                      id="country-street"
                      type="text"
                      fullWidth
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Grid>
                  <div className={classes.emailNotifContainer}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={emailNotifications}
                        onChange={(e) => setEmailNotifications(e.target.checked)}
                        // color="primary"
                      />
                    }
                    label="Receive Email Notifications"
                  />
                </div>
                </Grid>
                <ColorButton type="submit" fullWidth>
                  Save Changes
                </ColorButton>
              </form>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}
